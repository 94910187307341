<template>
  <v-form class="form mx-auto" @submit.prevent="resetPassword()">
    <v-text-field
      :value="form.email"
      :label="$t('user.fields.email')"
      type="email" :error-messages="getFieldErrors('email')"
      @input="form.email = $event.replace(/ /g, '').toLowerCase()"
      @blur="$v.form.email.$touch()"
    />
    <v-btn type="submit" class="mt-10 mb-4" color="primary" block depressed v-text="$t('user.sendPasswordResetEmail')" />
  </v-form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ResetPassword',
  mixins: [formUtils],
  data() {
    return {
      form: {
        email: null,
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
    },
  },
  methods: {
    resetPassword() {
      if (!this.isFormValid()) return
      this.runAsync(() => this.$store.dispatch('user/resetPassword', this.form.email))
    },
  },
}
</script>
