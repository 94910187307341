<template>
  <v-layout justify-center align-center fill-height class="grey lighten-4">
    <v-card class="pa-16 card text-center" elevation="18">
      <v-img src="@/assets/logo.svg" height="50" contain class="mb-10 mt-4" />
      <v-form class="form mx-auto" @submit.prevent="login">
        <v-text-field
          ref="email"
          :value="form.email" :label="$t('user.fields.email')"
          type="email" name="email"
          :error-messages="getFieldErrors('email')"
          @input="form.email = $event.replace(/ /g, '').toLowerCase()"
          @blur="$v.form.email.$touch()"
        />
        <v-text-field
          ref="password"
          v-model="form.password" :label="$t('user.fields.password')"
          :type="showPassword ? 'text' : 'password'" name="password"
          :error-messages="getFieldErrors('password')"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @blur="$v.form.password.$touch()"
        />
        <v-btn type="submit" class="mt-10 mb-4" color="primary" block depressed v-text="$t('user.login')" />
        <v-dialog max-width="700">
          <template #activator="{ on, attrs }">
            <p class="text-body-2" v-bind="attrs" v-on="on" v-text="$t('user.forgotPassword')" />
          </template>
          <v-card class="pa-16 text-center">
            <ResetPassword />
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>
  </v-layout>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import ResetPassword from '@/modules/user/ResetPassword'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'Login',
  components: {
    ResetPassword,
  },
  mixins: [formUtils],
  data() {
    return {
      showPassword: false,
      form: {
        email: null,
        password: null,
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  mounted() {
    setTimeout(() => {
      if (document.querySelector('input[type="email"]:-webkit-autofill')) this.$refs.email.$el.classList.add('autofilled')
      if (document.querySelector('input[type="password"]:-webkit-autofill')) this.$refs.password.$el.classList.add('autofilled')
    }, 300)
  },
  methods: {
    login() {
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        await this.$store.dispatch('user/login', { email: this.form.email, password: this.form.password })
        this.$router.push({ name: 'redirectAfterLogin' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  max-width: 700px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
}

.form {
  max-width: 300px;
}
</style>
